body{
    background-color: #EEEEEE;
}

@media print {
    body * {
        visibility: hidden;
    }
    #order-receipt, #order-receipt * {
        visibility: visible;
    }
    #order-receipt {
        position: absolute;
        left: 0;
        top: 0;
        
    }
}